/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";

const Map = (props) => {
  const { rpdata } = useContext(GlobalDataContext);
  return (
    <div>
      <div className="w-full mt-[60px]">
        {
          rpdata?.dbPrincipal?.miles.length > 1 ?
            <div>
              <h2 className="text-center">
                We Cover {rpdata?.dbPrincipal?.miles} Miles Around {rpdata?.dbPrincipal?.location?.[0].address}
              </h2>
              <iframe src={rpdata?.dbPrincipal?.location?.[0].url} className="w-full h-[500px]" />
            </div>
            : <div>
              <h2 className="text-center">
                we cover the entire state of:
              </h2>
              <div className="block md:flex gap-2">
                {
                  rpdata?.dbPrincipal?.location?.slice(1).map((item, index) => {
                    return (
                      <div key={index} className='w-full md:w-1/2 pt-10'>
                        <h2 className="text-center">{item.address}</h2>
                        <iframe src={item.url} className="w-full h-[500px]" />
                      </div>
                    )
                  })
                }
              </div>
            </div>
        }
      </div>
    </div>
  )
}

export default Map
